import BlackTwo from './black/2.svg';
import BlackThree from './black/3.svg';
import BlackFour from './black/4.svg';
import BlackFive from './black/5.svg';
import BlackSix from './black/6.svg';
import BlackSeven from './black/7.svg';
import BlackEight from './black/8.svg';
import BlackNine from './black/9.svg';
import BlackTen from './black/10.svg';
import BlackOne from './black/a.svg';
import BlackEleven from './black/j.svg';
import BlackThirteen from './black/k.svg';
import BlackTwelve from './black/q.svg';
import isRed from './isRed';
import RedTwo from './red/2.svg';
import RedThree from './red/3.svg';
import RedFour from './red/4.svg';
import RedFive from './red/5.svg';
import RedSix from './red/6.svg';
import RedSeven from './red/7.svg';
import RedEight from './red/8.svg';
import RedNine from './red/9.svg';
import RedTen from './red/10.svg';
import RedOne from './red/a.svg';
import RedEleven from './red/j.svg';
import RedThirteen from './red/k.svg';
import RedTwelve from './red/q.svg';

export const ALL_VALUES = Object.freeze([
  RedOne,
  RedTwo,
  RedThree,
  RedFour,
  RedFive,
  RedSix,
  RedSeven,
  RedEight,
  RedNine,
  RedTen,
  RedEleven,
  RedTwelve,
  RedThirteen,

  BlackOne,
  BlackTwo,
  BlackThree,
  BlackFour,
  BlackFive,
  BlackSix,
  BlackSeven,
  BlackEight,
  BlackNine,
  BlackTen,
  BlackEleven,
  BlackTwelve,
  BlackThirteen,
]);

const lookupValue = (value: number) => {
  const index = (value - 1) % 13;
  const offset = isRed(value) ? 0 : 13;

  return ALL_VALUES[index + offset];
};

export default lookupValue;
