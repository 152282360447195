import GameContainer from '../GameContainer';
import useDocumentTitle from '@tanem/use-document-title';
import PersistBalance from 'casino/balance/PersistBalance';
import CardProvider from 'casino/cards/Card/CardProvider';
import usePreload from 'games/casino/loading/usePreloadVideoPokerFamily';

type VideoPokerContainerProps = {
  children?: React.ReactNode;
  title: string;
};

const Container = ({ children }: { children?: React.ReactNode }) => (
  <GameContainer loadingPromise={usePreload()}>
    <PersistBalance>{children}</PersistBalance>
  </GameContainer>
);

const VideoPokerContainer = ({ children, title }: VideoPokerContainerProps) => {
  useDocumentTitle(`Lambton Games – ${title}`);

  return (
    <CardProvider>
      <Container>{children}</Container>
    </CardProvider>
  );
};

export default VideoPokerContainer;
