import Home from './routes/home';
import Cookies from './routes/legal/Cookies';
import Copyright from './routes/legal/Copyright';
import Privacy from './routes/legal/Privacy';
import Tanscs from './routes/legal/Tandcs';
import ScrollToTop from './window/scroll/ScrollToTop';
import ConsentOverlay from 'consents/ConsentOverlay';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DoubleDownRoute from 'routes/games/DoubleDown';
import PickAPairRoute from 'routes/games/PickAPair';
import VideoPoker from 'routes/games/VideoPoker';

const App = () => (
  <Router>
    <ScrollToTop />
    <ConsentOverlay />
    <Routes>
      <Route path="/terms-and-conditions" element={<Tanscs />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/cookies" element={<Cookies />} />
      <Route path="/copyright" element={<Copyright />} />
      <Route path="/games/double-down" element={<DoubleDownRoute />} />
      <Route path="/games/pick-a-pair" element={<PickAPairRoute />} />
      <Route path="/games/video-poker" element={<VideoPoker />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
);

export default App;
