import NonWildBasePayTableChecker from 'casino/PayTable/Checker/Base/NonWildBasePayTableChecker';
import { PayTableChecker, Result } from 'casino/PayTable/Checker/types';

class TensOrBetterChecker extends NonWildBasePayTableChecker implements PayTableChecker {
  readonly name: string = 'Tens or Better';
  private static readonly RANK_TENS_OR_BETTER = 'Tens or Better';

  protected rankAndPayouts: [string, number][] = [
    [NonWildBasePayTableChecker.RANK_ROYAL_FLUSH, 800],
    [NonWildBasePayTableChecker.RANK_STRAIGHT_FLUSH, 50],
    [NonWildBasePayTableChecker.RANK_FOUR_OF_A_KIND, 25],
    [NonWildBasePayTableChecker.RANK_FULL_HOUSE, 6],
    [NonWildBasePayTableChecker.RANK_FLUSH, 5],
    [NonWildBasePayTableChecker.RANK_STRAIGHT, 4],
    [NonWildBasePayTableChecker.RANK_THREE_OF_A_KIND, 3],
    [NonWildBasePayTableChecker.RANK_TWO_PAIRS, 2],
    [TensOrBetterChecker.RANK_TENS_OR_BETTER, 1],
  ];

  getHandResult(hand: number[]): Result {
    const isFlush = TensOrBetterChecker.isFlush(hand);
    const isStraight = TensOrBetterChecker.isStraight(hand);

    if (isFlush && isStraight) {
      if (TensOrBetterChecker.containsFaceValue(hand, 1) && TensOrBetterChecker.containsFaceValue(hand, 10)) {
        return TensOrBetterChecker.createAllWin(TensOrBetterChecker.RANK_ROYAL_FLUSH);
      }

      return TensOrBetterChecker.createAllWin(TensOrBetterChecker.RANK_STRAIGHT_FLUSH);
    }

    if (TensOrBetterChecker.isNofKind(hand, 4)) {
      return TensOrBetterChecker.createPairWin(TensOrBetterChecker.RANK_FOUR_OF_A_KIND, hand);
    }

    if (TensOrBetterChecker.isFullHouse(hand)) {
      return TensOrBetterChecker.createAllWin(TensOrBetterChecker.RANK_FULL_HOUSE);
    }

    if (isFlush) {
      return TensOrBetterChecker.createAllWin(TensOrBetterChecker.RANK_FLUSH);
    }

    if (isStraight) {
      return TensOrBetterChecker.createAllWin(TensOrBetterChecker.RANK_STRAIGHT);
    }

    if (TensOrBetterChecker.isNofKind(hand, 3)) {
      return TensOrBetterChecker.createPairWin(TensOrBetterChecker.RANK_THREE_OF_A_KIND, hand);
    }

    if (TensOrBetterChecker.isTwoPair(hand)) {
      return TensOrBetterChecker.createPairWin(TensOrBetterChecker.RANK_TWO_PAIRS, hand);
    }

    if (TensOrBetterChecker.isValueOrBetter(hand, 10)) {
      return TensOrBetterChecker.createPairWin(TensOrBetterChecker.RANK_TENS_OR_BETTER, hand);
    }

    return TensOrBetterChecker.NO_WIN;
  }
}

export default new TensOrBetterChecker();
