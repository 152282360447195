import styles from './Menu.module.scss';
import { useBurgerMenu } from 'burger';
import clsx from 'clsx';
import { useCallback } from 'react';

type OpenMenuProps = {
  'aria-labelledby': string;
  children?: React.ReactNode;
  className?: string;
};

const OpenMenu = ({ 'aria-labelledby': labelledBy, children, className }: OpenMenuProps) => {
  const { closeMenu } = useBurgerMenu();

  return (
    <div className={styles.overlay} data-testid="burger-overlay" onClick={useCallback(() => closeMenu(), [closeMenu])}>
      <div
        aria-labelledby={labelledBy}
        className={clsx(styles.menu, className)}
        role="dialog"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default OpenMenu;
