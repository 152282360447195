import Club from './suitclub.svg';
import Diamond from './suitdiamond.svg';
import Heart from './suitheart.svg';
import Spade from './suitspade.svg';

export const ALL_SUITS = Object.freeze([Heart, Diamond, Club, Spade]);

const lookupSuit = (value: number) => {
  const ret = ALL_SUITS[Math.floor((value - 1) / 13)];

  if (ret) {
    return ret;
  }

  throw new Error(`no suit image for card with value '${value}'`);
};

export default lookupSuit;
