import styles from '../VideoPokerMenu.module.scss';
import Dollar from '../icons/Dollar';
import clsx from 'clsx';
import { useCallback } from 'react';

type ListItemProps = {
  isSelected?: boolean;
  name: string;
  onClick: () => void;
  hasIcon?: true;
};

const ListItem = ({ isSelected, name, hasIcon, onClick }: ListItemProps) => {
  return (
    <li
      className={clsx(styles.item, isSelected && styles.selected)}
      onClick={useCallback(() => onClick(), [onClick])}
      aria-label={name}
      data-is-selected={isSelected ? true : undefined}
    >
      {hasIcon && <Dollar />}
      <span>{name}</span>
    </li>
  );
};

export default ListItem;
