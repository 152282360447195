import { createContext } from 'react';

export type PlayingCardContextData = Readonly<{
  isLoading: boolean;
  getDataUrlSvg: (imagePath: string) => string;
}>;

const Context = createContext<PlayingCardContextData | undefined>(undefined);
Context.displayName = 'PlayingCardContext';

export default Context;
