import { ReducerState } from '../types';
import throwError from '@ducktorscience/throw-error';

const handleReset = (state: ReducerState): ReducerState => {
  return {
    ...state,
    phase: 'initial',
    result: null,
    cardValues: [undefined, undefined, undefined, undefined, undefined],
    held: [false, false, false, false, false],
    stock: [],
    checker: {
      getHandResult: throwError('reset state checker not expected to ever be called'),
      rows: [],
    },
  };
};

export default handleReset;
