import ICallbacks from './ICallbacks';
import TimeoutService from 'window/timeout/TimeoutService';

type SubscriptionCallback<T> = (data: T) => void;

class Callbacks<T> implements ICallbacks<T> {
  private readonly callbacks = new Set<SubscriptionCallback<T>>();

  public fireCallbacks(data: T): void {
    TimeoutService.defer(() => {
      this.callbacks.forEach((callback) => {
        callback(data);
      });
    });
  }

  public subscribe(onChange: (data: T) => void): () => void {
    this.callbacks.add(onChange);

    return () => {
      this.callbacks.delete(onChange);
    };
  }
}

export default Callbacks;
