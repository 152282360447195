import { GamePhase, GameState } from '../types';
import { toggleHoldActionCreator } from './reducer/actionCreators';
import useDealDrawActions from './useDealDrawActions';
import useGameStateReducer from './useGameStateReducer';
import { PayTableChecker } from 'casino/PayTable/Checker/types';
import { GAME_PERSISTENCE_KEY } from 'games/VideoPoker/constants';
import useCoins from 'games/casino/useCoins';
import { useCallback, useEffect } from 'react';
import TimeoutService from 'window/timeout/TimeoutService';

export const CARD_FLIP_DELAY = 200;

const isAnimatingPhase = (phase: GamePhase) => phase === 'animating-deal' || phase === 'animating-draw';

const useGameState = (checker: PayTableChecker): GameState => {
  const { coinSize, cycleCoinSize, cycleTotalCoins, totalCoins } = useCoins(GAME_PERSISTENCE_KEY);
  const stake = coinSize * totalCoins;
  const { dispatch, stock, ...gameState } = useGameStateReducer(checker);
  const { getDealAction, getDrawAction } = useDealDrawActions(checker, stake);

  const { phase, cardValues } = gameState;

  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [stake, dispatch]);

  // When in an animating phase schedule the next card reveal each time the number of revealed cards changes
  useEffect(() => {
    // Because this will be called when exiting animated state or when not animating but the deck changes, specifically check we are in an animating phase
    if (isAnimatingPhase(phase)) {
      const timerId = TimeoutService.defer(() => {
        dispatch({ type: 'animation tick' });
      }, CARD_FLIP_DELAY);

      return () => {
        TimeoutService.cancel(timerId);
      };
    }
  }, [phase, cardValues, dispatch]);

  const dealDraw = useCallback(() => {
    const isDrawLikePhase = phase === 'awaiting-draw' || phase === 'animating-draw';
    dispatch(isDrawLikePhase ? getDrawAction() : getDealAction());
  }, [phase, dispatch, getDealAction, getDrawAction]);

  return {
    bet: stake,
    ...gameState,

    toggleHold: useCallback((n: number) => dispatch(toggleHoldActionCreator(n)), [dispatch]),
    dealDraw,
    cycleTotalCoins,
    cycleCoinSize,

    coinSize,
    totalCoins,
  };
};

export default useGameState;
