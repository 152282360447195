import styles from './styles.module.scss';
import GoToRoute from 'routes/links/GoToRoute';

const LinkBar = () => (
  <div className={styles.linkBar}>
    <GoToRoute name="Copyright" destination="/copyright" />
    <GoToRoute name="Terms and Conditions" destination="/terms-and-conditions" />
    <GoToRoute name="Cookies" destination="/cookies" />
    <GoToRoute name="Privacy" destination="/privacy" />
  </div>
);

export default LinkBar;
