class FileReaderFactory {
  create(
    onloadend: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null,
    onerror: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null
  ) {
    const reader = new FileReader();
    reader.onerror = onerror;
    reader.onloadend = onloadend;
    return reader;
  }

  createDataURLLoader(
    onloadend: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null,
    onerror: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null
  ) {
    const reader = this.create(onloadend, onerror);

    return reader as Pick<FileReader, 'readAsDataURL' | 'onerror' | 'onloadend' | 'result'>;
  }
}

export default new FileReaderFactory();
