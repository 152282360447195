import { DealAction, ReducerState } from '../types';

const handleDeal = (state: ReducerState, dealAction: DealAction): ReducerState => {
  const { checker, deck: sourceDeck, stake } = dealAction.payload;
  const deck = [...sourceDeck].splice(0, 10);

  return {
    ...state,
    cardValues: [undefined, undefined, undefined, undefined, undefined],
    checker,
    phase: 'animating-deal',
    result: null,
    stake,
    stock: deck,
  };
};

export default handleDeal;
