import { COIN_SIZES } from './constants';
import _clamp from 'lodash/clamp';
import { useGetItem, useSetItem } from 'persistence/usePersistence';
import { useCallback, useMemo, useState } from 'react';

type UseCoinsResult = {
  cycleCoinSize: () => void;
  cycleTotalCoins: () => void;

  coinSize: number;
  totalCoins: number;
};

const useInitialNumber = (gameKey: string, itemKey: string, fallback: number): number => {
  const getItem = useGetItem();
  const fullKey = `${gameKey}.${itemKey}`;

  return useMemo(() => {
    const raw = getItem(fullKey);
    if (raw !== null) {
      const parsed = parseInt(raw, 10);

      if (!Number.isNaN(parsed)) {
        return parsed;
      }
    }

    return fallback;
  }, [fullKey, fallback, getItem]);
};

const useSaveNumber = (gameKey: string, itemKey: string) => {
  const setItem = useSetItem();
  const fullKey = `${gameKey}.${itemKey}`;

  return useCallback(
    (newValue: number) => {
      setItem(fullKey, newValue.toString());
    },
    [setItem, fullKey]
  );
};

const useCoins = (gameKey: string, coinSizes: Readonly<number[]> = COIN_SIZES): UseCoinsResult => {
  const saveTotalCoins = useSaveNumber(gameKey, 'total-coins');
  const [totalCoins, setTotalCoins] = useState(useInitialNumber(gameKey, 'total-coins', 5));
  const cycleTotalCoins = useCallback(() => {
    setTotalCoins((existing) => {
      const newValue = existing >= 5 ? 1 : existing + 1;

      saveTotalCoins(newValue);

      return newValue;
    });
  }, [saveTotalCoins]);

  const saveCoinSize = useSaveNumber(gameKey, 'coin-size');
  const [coinSize, setCoinSize] = useState(useInitialNumber(gameKey, 'coin-size', 1));
  const cycleCoinSize = useCallback(() => {
    setCoinSize((existing) => {
      const nextIndex = _clamp(coinSizes.indexOf(existing), 0, coinSizes.length - 1) + 1;
      const newValue = coinSizes[nextIndex === coinSizes.length ? 0 : nextIndex];
      saveCoinSize(newValue);
      return newValue;
    });
  }, [coinSizes, saveCoinSize]);

  return {
    coinSize,
    totalCoins,
    cycleCoinSize,
    cycleTotalCoins,
  };
};

export default useCoins;
