import { randomBetween } from '..';

/**
 * Shuffle using the fisher yates algorithm
 * The source array will be mutated
 *
 * @param array source array to shuffle. will be mutated
 */
function shuffleArrayInPlace<T>(array: T[]) {
  let index = -1;
  const size = array.length;
  const lastIndex = size - 1;

  while (++index < size) {
    const randomNumber = randomBetween(index, lastIndex);
    const value = array[randomNumber];

    array[randomNumber] = array[index];
    array[index] = value;
  }
}

export default shuffleArrayInPlace;
