import WagerContext from './WagerContext';
import AlertDialog from 'alert/AlertDialog';
import { getResetBalanceValue } from 'casino/balance/PersistBalance';
import useBalanceData from 'casino/balance/useBalanceData';
import React, { useCallback, useState } from 'react';

type AlertMode = false | 'insufficient' | 'manual';

const WagerExecutor = ({ children }: { children?: React.ReactNode }) => {
  const { balance, adjustBalance, resetBalance } = useBalanceData();
  const [showAlert, setShowAlert] = useState<AlertMode>();

  const onDismissAlert = useCallback(() => setShowAlert(false), []);
  const onReset = useCallback(() => {
    resetBalance();
    setShowAlert(false);
  }, [resetBalance]);

  return (
    <WagerContext.Provider
      value={{
        makeDeduction: (amount, then) => {
          if (amount <= balance) {
            adjustBalance(-amount);
            then();
          } else {
            setShowAlert('insufficient');
          }
        },
        manuallyResetBalance: () => {
          setShowAlert('manual');
        },
      }}
    >
      <>
        {children}
        {showAlert && (
          <AlertDialog
            title="Reset balance?"
            message={
              showAlert === 'insufficient'
                ? 'Your balance is not large enough to make this bet. Would you like to reset your balance?'
                : `Reset your balance to ${getResetBalanceValue()}?`
            }
            onNo={onDismissAlert}
            onYes={onReset}
          />
        )}
      </>
    </WagerContext.Provider>
  );
};

export default WagerExecutor;
