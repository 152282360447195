import DoubleDown from 'games/DoubleDown';
import VideoPokerContainer from 'games/casino/container/VideoPokerContainer';

const DoubleDownRoute = () => (
  <VideoPokerContainer title="Double Down Poker">
    <DoubleDown />
  </VideoPokerContainer>
);

export default DoubleDownRoute;
