import PickAPair from 'games/PickAPair';
import VideoPokerContainer from 'games/casino/container/VideoPokerContainer';

const PickAPairRoute = () => (
  <VideoPokerContainer title="Pick A Pair Poker">
    <PickAPair />
  </VideoPokerContainer>
);

export default PickAPairRoute;
