import Context from './Context';
import PersistenceService from './PersistenceService';
import { useCallback, useContext, useMemo } from 'react';

type GetItem = (key: string) => string | null;
type SetItem = (key: string, value: string) => void;

type PersistenceFunctions = {
  getItem: GetItem;
  removeItem(key: string): void;
  setItem: SetItem;
};

const usePrefix = (): string => {
  const namespace = useContext(Context);
  return namespace + '.';
};

export const useGetItem = (): GetItem => {
  const prefix = usePrefix();

  return useCallback((key: string) => PersistenceService.getItem(`${prefix}${key}`), [prefix]);
};

export const useSetItem = (): SetItem => {
  const prefix = usePrefix();

  return useCallback((key: string, value: string) => PersistenceService.setItem(`${prefix}${key}`, value), [prefix]);
};

const usePersistence = (): PersistenceFunctions => {
  const prefix = usePrefix();

  const getItem = useGetItem();
  const setItem = useSetItem();

  return useMemo(
    () => ({
      getItem,
      removeItem: (key: string) => PersistenceService.removeItem(`${prefix}${key}`),
      setItem,
    }),
    [prefix, getItem, setItem]
  );
};

export default usePersistence;
