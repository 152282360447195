import animatingDealReducer from './animatingDealReducer';
import animatingDrawReducer from './animatingDrawReducer';
import awaitingDrawReducer from './awaitingDrawReducer';
import gameOverReducer from './gameOverReducer';
import handleReset from './handle/reset';
import initialStateReducer from './initialStateReducer';
import { ReducerState, VideoPokerAction } from './types';

const reducer = (state: ReducerState, action: VideoPokerAction): ReducerState => {
  if (action.type === 'reset') {
    return handleReset(state);
  }

  switch (state.phase) {
    case 'animating-deal':
      return animatingDealReducer(state, action);
    case 'animating-draw':
      return animatingDrawReducer(state, action);
    case 'awaiting-draw':
      return awaitingDrawReducer(state, action);
    case 'game-over':
      return gameOverReducer(state, action);
    case 'initial':
      return initialStateReducer(state, action);
  }
};

export default reducer;
