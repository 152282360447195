import SitePage from '../../../pages/SitePage';
import styles from './Cookies.module.scss';
import useDocumentTitle from '@tanem/use-document-title';
import useConsents from 'consents/ConsentOverlay/useConsents';
import { Link } from 'react-router-dom';

const Cookies = () => {
  useDocumentTitle('Lambton Games – Cookies');
  const { performanceAccepted, acceptAll, declineAll } = useConsents();

  return (
    <SitePage className={styles.root}>
      <section>
        <h2>Cookies</h2>
        <p>
          Cookies are small text files which are transferred to your computer or mobile when you visit a website or app.
        </p>

        <p>
          You can find out more <Link to="/privacy">here</Link>.
        </p>
        <p>Please check your cookie settings below and turn on any cookies you’re happy with.</p>
      </section>
      <section>
        <form>
          <fieldset>
            <legend>Necessary cookies</legend>
            <label>
              <input type="checkbox" checked={true} readOnly />
              These cookies are essential so that you can move around the website and use its features. Without these
              cookies services you have asked for cannot be provided.
            </label>
          </fieldset>

          <fieldset>
            <legend>Performance cookies</legend>
            <label>
              <input
                type="checkbox"
                checked={performanceAccepted}
                onChange={(e) => {
                  if (e.target.checked) {
                    acceptAll();
                  } else {
                    declineAll();
                  }
                }}
              />
              These cookies help us monitor and improve performance of the website.
            </label>
          </fieldset>
        </form>
      </section>
    </SitePage>
  );
};

export default Cookies;
