import Context from './Context';
import useAllImagePaths from './images/useAllImagePaths';
import throwError from '@ducktorscience/throw-error';
import { useDataUrl } from 'images/base64/useDataUrl';

const Provider = ({ children }: { children: React.ReactNode }) => {
  const allPaths = useAllImagePaths();

  const { loading, lookupDataUrl } = useDataUrl(allPaths);

  return (
    <Context.Provider
      value={{
        isLoading: loading,
        getDataUrlSvg: loading ? throwError('getDataUrlSvg called before loading complete') : lookupDataUrl,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
