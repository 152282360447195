import { FileReaderFactory } from '../FileReader';
import { PromiseFactory } from 'async/promise';

const blobToDataURL = (blob: Blob): Promise<string> => {
  const { promise, resolve, reject } = PromiseFactory.create<string>();
  const fileReader = FileReaderFactory.createDataURLLoader(() => resolve(fileReader.result as string), reject);
  fileReader.readAsDataURL(blob);

  return promise;
};

export default blobToDataURL;
