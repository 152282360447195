import Context from './Context';
import { useCallback, useContext } from 'react';

const useToggleInProgressAlert = () => {
  const contextMaybe = useContext(Context);

  const stableSetter = useCallback(
    (showAlert: boolean) => {
      if (contextMaybe) {
        contextMaybe(showAlert);
      }
    },
    [contextMaybe]
  );

  return stableSetter;
};

export default useToggleInProgressAlert;
