import { ReducerState, VideoPokerAction } from './types';

const animatingDealReducer = (state: ReducerState, action: VideoPokerAction): ReducerState => {
  switch (action.type) {
    case 'deal': {
      const [...stock] = state.stock;

      const cardValues = state.cardValues.filter(Number);
      while (cardValues.length < 5) {
        cardValues.push(stock.shift());
      }

      return {
        ...state,
        stock,
        cardValues,
        phase: 'awaiting-draw',
      };
    }
    case 'animation tick': {
      const [nextCard, ...stock] = state.stock;

      const cardValues = state.cardValues.filter(Number);
      cardValues.push(nextCard);

      const isDone = cardValues.length === 5;

      while (cardValues.length < 5) {
        cardValues.push(undefined);
      }

      return {
        ...state,
        cardValues,
        phase: isDone ? 'awaiting-draw' : 'animating-deal',
        stock,
      };
    }
  }

  return state;
};

export default animatingDealReducer;
