import styles from './VideoPokerCard.module.scss';
import ScreenShotLarge from './screen-large.png';
import { Link } from 'react-router-dom';
import { NotPhone, Phone } from 'screen/devices';

const VideoPokerCard = () => (
  <Link to="/games/video-poker">
    <div className={styles.card}>
      <div className={styles.title}>Video Poker</div>
      <div className={styles.playNow}>Play Now</div>
      <div className={styles.screenshot}>
        <NotPhone>
          <img src={ScreenShotLarge} alt="" width={457} height={114} />
        </NotPhone>
        <Phone>
          <img src={ScreenShotLarge} alt="" width={457 / 2} height={114 / 2} />
        </Phone>
      </div>
    </div>
  </Link>
);

export default VideoPokerCard;
