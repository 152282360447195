import styles from './GameContainer.module.css';
import LoadingScreen from 'casino/LoadingScreen';
import { Provider as LoseInProgressProvider } from 'gaming/LoseInProgress';
import { FillPage } from 'layout';

type GameContainerProps = {
  children?: React.ReactNode;
  loadingPromise: Promise<void>;
};

const GameContainer = ({ children, loadingPromise }: GameContainerProps) => {
  return (
    <FillPage className={styles.root}>
      <LoseInProgressProvider>
        <LoadingScreen waitOn={loadingPromise}>{children}</LoadingScreen>
      </LoseInProgressProvider>
    </FillPage>
  );
};

export default GameContainer;
