import { createContext } from 'react';

export type BurgerValue = {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
};

const BurgerContext = createContext<BurgerValue | undefined>(undefined);

export default BurgerContext;
