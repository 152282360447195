import styles from './LoadingScreen.module.scss';
import { useEffect, useState } from 'react';

type LoadingScreenProps = {
  children?: React.ReactNode;
  waitOn: Promise<unknown>;
};

const LoadingPane = () => (
  <div className={styles.root}>
    <div data-testid="loading-message">Loading…</div>
    <div data-testid="loading-indicator" className={styles.loadingBar}>
      <div className={styles.loadingBarValue} />
    </div>
  </div>
);

const LoadingScreen = ({ children, waitOn }: LoadingScreenProps) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let cleanedUp = false;

    waitOn.then(() => {
      if (!cleanedUp) {
        setLoaded(true);
      }
    });

    return () => {
      cleanedUp = true;
    };
  }, [waitOn]);

  return loaded ? <>{children}</> : <LoadingPane />;
};

export default LoadingScreen;
