import styles from './CardCell.module.scss';
import Card from 'casino/cards/Card';
import clsx from 'clsx';

type CardCellProps = {
  acceptingInput: boolean;
  fade: boolean;
  held: boolean;
  onClick?: () => void;
  value: number | undefined;
};

const Hold = () => (
  <div className={styles.hold} data-testid="hold-banner">
    HOLD
  </div>
);

const CardCell = ({ acceptingInput, fade, held, onClick, value }: CardCellProps) => (
  <div className={styles.root} data-testid="card-cell" onClick={onClick ? () => onClick() : undefined}>
    <div className={styles.insides}>
      <Card
        className={clsx(fade && value !== undefined && styles.fade, acceptingInput && styles.input)}
        value={value}
      />
      {held && <Hold />}
    </div>
  </div>
);

export default CardCell;
