import FooterButton from './FooterButton';
import facebook from './images/facebook.png';
import twitter from './images/twitter.png';
import youtube from './images/youtube.png';
import styles from './styles.module.scss';

function FooterButtonBar() {
  return (
    <div className={styles.footerButtonBar}>
      <FooterButton src={twitter} name="Twitter" destination="https://twitter.com/lambtongames" />
      <FooterButton src={facebook} name="Facebook" destination="https://facebook.com" />
      <FooterButton
        src={youtube}
        name="YouTube"
        destination="https://www.youtube.com/channel/UC1V5_b9Xt7N_Sm8zZftXWXA"
      />
    </div>
  );
}

export default FooterButtonBar;
