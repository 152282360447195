import styles from './styles.module.scss';

type HudElementProps = {
  caption: string;
  value: number;
};

const HudElement = ({ caption, value }: HudElementProps) => (
  <div className={styles.element}>
    <span className={styles.caption}>{caption}</span>
    <span className={styles.value} data-testid={`hud-element-value-${caption}`}>
      {value}
    </span>
  </div>
);

type HudProps = {
  balance: number;
  bet: number;
  win: number;
};

const Hud = ({ balance, bet, win }: HudProps) => {
  return (
    <div className={styles.root}>
      <HudElement caption="BET" value={bet} />
      <HudElement caption="WIN" value={win} />
      <HudElement caption="BALANCE" value={balance} />
    </div>
  );
};

export default Hud;
