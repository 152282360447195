import Context from './Context';
import { PromiseFactory } from 'async/promise';
import { useContext, useEffect, useMemo } from 'react';

const usePreloadCards = (): Promise<void> => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('usePreloadCards called outside of a Context');
  }

  const { promise, resolve } = useMemo(() => PromiseFactory.create<void>(), []);

  useEffect(() => {
    if (!context.isLoading) {
      resolve();
    }
  }, [context.isLoading, resolve]);

  return promise;
};

export default usePreloadCards;
