import SitePage from '../../pages/SitePage';
import AndroidGames from './AndroidGames';
import Section from './Section';
import VideoPokerCard from './VideoPokerCard';
import styles from './styles.module.scss';

const Home = () => {
  return (
    <SitePage className={styles.root}>
      <Section className={styles.section} title="Browser Games">
        <VideoPokerCard />
      </Section>

      <Section className={styles.section} title="Android Games">
        <AndroidGames />
      </Section>
    </SitePage>
  );
};

export default Home;
