import { handleDeal } from './handle';
import { ReducerState, VideoPokerAction } from './types';

const initialStateReducer = (state: ReducerState, action: VideoPokerAction): ReducerState => {
  switch (action.type) {
    case 'deal':
      return handleDeal(state, action);
  }

  return state;
};

export default initialStateReducer;
