import { HandResult } from '../../types';
import allCardsAreRevealed from './allCardsAreRevealed';
import { InternalStateData } from './types';

const inferHandResult = (
  cardValues: Array<number | undefined>,
  {
    checker,
    stake,
  }: {
    checker: InternalStateData['checker'];
    stake: number;
  }
): HandResult | null => {
  const allRevealed = allCardsAreRevealed(cardValues);

  if (allRevealed) {
    const { rank, winningCards } = checker.getHandResult(cardValues);
    const winningHand = checker.rows.find((row) => row.rank === rank);

    if (winningHand) {
      return {
        rank,
        winningCards,
        win: winningHand.payout * stake,
      };
    }
  }

  return null;
};

export default inferHandResult;
