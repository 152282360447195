export type Suit = 'hearts' | 'diamonds' | 'clubs' | 'spades';

export type CardString = `${number}-${Suit}`;

function isCardString(maybe: CardString | number): maybe is CardString {
  return typeof maybe === 'string';
}

class CardFactory {
  private static getAddition(suit?: string): number {
    switch (suit) {
      case 'diamonds':
        return 13;
      case 'clubs':
        return 26;
      case 'spades':
        return 39;
    }

    return 0;
  }

  create(values: CardString[]): number[];
  create(value: CardString): number;
  create(value: number, suit: Suit): number;
  create(value: number | CardString | CardString[], suit?: Suit): number | number[] {
    if (Array.isArray(value)) {
      return value.map(this.create);
    }

    if (isCardString(value)) {
      const [valueStr, suitRaw] = value.split('-');
      return Number(valueStr) + CardFactory.getAddition(suitRaw);
    }

    return value + CardFactory.getAddition(suit);
  }
}

export default new CardFactory();
