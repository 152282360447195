import { DealAction, VideoPokerAction } from '../reducer/types';
import { PayTableChecker } from 'casino/PayTable/Checker/types';
import DeckFactory from 'games/VideoPoker/GameArea/DeckFactory';

type ActiveAction = VideoPokerAction | DealAction;

function isDeal(activeAction: ActiveAction): activeAction is DealAction {
  return activeAction.type === 'deal';
}

export default class DealDrawActionProvider {
  private activeAction: ActiveAction | null = null;

  constructor(private readonly checker: PayTableChecker, private readonly stake: number) {}

  private createDealAction(): DealAction {
    return {
      type: 'deal',
      payload: {
        checker: this.checker,
        deck: DeckFactory.createNewShuffledSingleDeck(),
        stake: this.stake,
      },
    };
  }

  private createDrawAction(): VideoPokerAction {
    return {
      type: 'draw',
    };
  }

  getDealAction(): DealAction {
    if (!this.activeAction || !isDeal(this.activeAction)) {
      this.activeAction = this.createDealAction();
    }

    return this.activeAction;
  }

  getDrawAction(): VideoPokerAction {
    if (this.activeAction === null) {
      throw new Error('cannot get draw action before deal');
    }

    if (isDeal(this.activeAction)) {
      this.activeAction = this.createDrawAction();
    }

    return this.activeAction;
  }
}
