import { HasRowsAndGetHandResult, Result } from 'casino/PayTable/Checker/types';
import { PayRow } from 'casino/PayTable/types';

const numericalComparator = (a: number, b: number) => a - b;

type RankAndPayout = [string, number];
type RankAndPayouts = RankAndPayout[];

export default abstract class NonWildBasePayTableChecker implements HasRowsAndGetHandResult {
  protected static readonly RANK_ROYAL_FLUSH = 'Royal Flush';
  protected static readonly RANK_STRAIGHT_FLUSH = 'Straight Flush';
  protected static readonly RANK_FOUR_OF_A_KIND = 'Four of a Kind';
  protected static readonly RANK_FULL_HOUSE = 'Full House';
  protected static readonly RANK_FLUSH = 'Flush';
  protected static readonly RANK_STRAIGHT = 'Straight';
  protected static readonly RANK_THREE_OF_A_KIND = 'Three of a Kind';
  protected static readonly RANK_TWO_PAIRS = 'Two Pairs';

  protected abstract rankAndPayouts: RankAndPayouts;

  private cachedRows: PayRow[] | null = null;
  public get rows(): readonly PayRow[] {
    if (this.cachedRows === null) {
      this.cachedRows = this.rankAndPayouts.map(([rank, payout]) => {
        return Object.freeze<PayRow>({
          rank,
          payout,
        });
      });
    }

    return this.cachedRows;
  }

  abstract getHandResult(hand: number[]): Result;

  protected static getSuit(cardValue: number): number {
    return Math.floor((cardValue - 1) / 13);
  }

  protected static getFaceValue(cardValue: number): number {
    return ((cardValue - 1) % 13) + 1;
  }

  protected static readonly ALL_WINNING = Object.freeze([true, true, true, true, true]) as boolean[];

  protected static readonly NO_WIN: Result = Object.freeze<Result>({
    rank: 'No win',
    winningCards: Object.freeze([false, false, false, false, false]) as boolean[],
  });

  protected static isNoWinResult({ rank }: Result): boolean {
    return rank === NonWildBasePayTableChecker.NO_WIN.rank;
  }

  protected static toFaceValues(hand: number[]): number[] {
    return hand.map((card) => NonWildBasePayTableChecker.getFaceValue(card));
  }

  protected static isConsecutive(numbers: number[]): boolean {
    let expected: number = numbers[0];

    for (let i = 0; i < numbers.length; ++i) {
      if (numbers[i] !== expected) {
        return false;
      }

      expected++;
    }

    return true;
  }

  protected static isFlush(hand: number[]): boolean {
    const first = NonWildBasePayTableChecker.getSuit(hand[0]);

    return hand.every((value) => first === NonWildBasePayTableChecker.getSuit(value));
  }

  protected static isStraight(hand: number[]): boolean {
    const sortedFaceValues = NonWildBasePayTableChecker.toFaceValues(hand).sort(numericalComparator);

    if (this.isConsecutive(sortedFaceValues)) {
      return true;
    }

    const sortedAcesAre14s = sortedFaceValues
      .map((faceValue) => (faceValue === 1 ? 14 : faceValue))
      .sort(numericalComparator);
    return this.isConsecutive(sortedAcesAre14s);
  }

  protected static createAllWin(rank: string): Result {
    return {
      rank,
      winningCards: NonWildBasePayTableChecker.ALL_WINNING,
    };
  }

  protected static containsFaceValue(hand: number[], faceValue: number): boolean {
    return hand.some((cardValue) => NonWildBasePayTableChecker.getFaceValue(cardValue) === faceValue);
  }

  protected static isNofKind(hand: number[], n: number): boolean {
    const found = new Map<number, number>();
    let biggestFound = 1;

    NonWildBasePayTableChecker.toFaceValues(hand).forEach((faceValue) => {
      const existing = found.get(faceValue);
      if (existing === undefined) {
        found.set(faceValue, 1);
      } else {
        const newTotal = existing + 1;
        found.set(faceValue, newTotal);
        biggestFound = Math.max(biggestFound, newTotal);
      }
    });

    return biggestFound >= n;
  }

  protected static createPairWin(rank: string, hand: number[]): Result {
    const found = new Map<number, number>();

    const faceValues = NonWildBasePayTableChecker.toFaceValues(hand);

    faceValues.forEach((faceValue) => {
      const existing = found.get(faceValue);
      if (existing === undefined) {
        found.set(faceValue, 1);
      } else {
        found.set(faceValue, existing + 1);
      }
    });

    return {
      rank,
      winningCards: faceValues.map((faceValue) => {
        const occourences = found.get(faceValue);

        return occourences !== undefined && occourences >= 2;
      }),
    };
  }

  protected static isFullHouse(hand: number[]): boolean {
    const faceValuesInHand = new Set<number>();

    NonWildBasePayTableChecker.toFaceValues(hand).forEach((faceValue) => faceValuesInHand.add(faceValue));

    return faceValuesInHand.size === 2;
  }

  protected static isTwoPair(hand: number[]): boolean {
    const faceValuesInHand = new Set<number>();

    NonWildBasePayTableChecker.toFaceValues(hand).forEach((faceValue) => faceValuesInHand.add(faceValue));

    return faceValuesInHand.size === 3;
  }

  protected static isValueOrBetter(hand: number[], value: number): boolean {
    const found = new Map<number, number>();
    let biggestFound = 1;

    NonWildBasePayTableChecker.toFaceValues(hand)
      .filter((faceValue) => faceValue >= value || faceValue === 1)
      .forEach((faceValue) => {
        const existing = found.get(faceValue);
        if (existing === undefined) {
          found.set(faceValue, 1);
        } else {
          const newTotal = existing + 1;
          found.set(faceValue, newTotal);
          biggestFound = Math.max(biggestFound, newTotal);
        }
      });

    return biggestFound === 2;
  }
}
