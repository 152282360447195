import ScreenSizeChangedObserverFactory from '../ScreenSizeChangedObserverFactory';
import ScreenSizes, { ScreenType } from '../ScreenSizes';
import { useEffect, useState } from 'react';

function useScreenSize(): ScreenType {
  const [screenSize, setScreenSize] = useState(ScreenSizes.getScreenType);

  useEffect(() => {
    return ScreenSizeChangedObserverFactory.createListener(() => {
      setScreenSize(ScreenSizes.getScreenType());
    });
  }, []);

  return screenSize;
}

export default useScreenSize;
