import styles from './CrossButton.module.scss';
import clsx from 'clsx';
import { useCallback } from 'react';

const CrossRect = ({ transform, y }: { transform?: string; y: number | string }) => (
  <rect width="46" height="8" x="1" y={y} rx="3" transform={transform} />
);

type CrossButtonProps = {
  className?: string;
  onClick: () => void;
};

const CrossButton = ({ className, onClick }: CrossButtonProps) => (
  <div
    className={clsx(styles.root, className)}
    role="button"
    aria-label="Close"
    onClick={useCallback(() => {
      onClick();
    }, [onClick])}
  >
    <svg width="24" height="24" viewBox="0 0 48 48">
      <g transform="rotate(45,24,24)">
        <CrossRect y="20" />
        <CrossRect y="-28" transform="rotate(90)" />
      </g>
    </svg>
  </div>
);

export default CrossButton;
