import { Link } from 'react-router-dom';

interface Props {
  name: string;
  destination: string;
}

function GoToRoute(props: Props) {
  return <Link to={props.destination}>{props.name}</Link>;
}

export default GoToRoute;
