import useCardImages from './images/useCardImages';
import styles from './styles.module.scss';

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
  value: number | undefined;
}

interface FaceUpProps {
  value: number;
}

const FaceUp = ({ value }: FaceUpProps) => {
  const { suitSrc, valueSrc } = useCardImages(value);
  const aspectRatio = 2.5 / 3.5;

  const indexWidth = '20%';
  const indexHeight = `${20 * aspectRatio}%`;

  return (
    <>
      <image x="5%" y="50%" width="90%" height="60%" href={suitSrc} />
      <image x="5%" y="5%" width={indexWidth} height={indexHeight} href={valueSrc} />
      <image x="5%" y="25%" width={indexWidth} height={indexHeight} href={suitSrc} />
      <image x="40%" y="5%" width="55%" height={`${55 * aspectRatio}%`} href={valueSrc} />
    </>
  );
};

const FaceDown = () => (
  <>
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(300)">
        <stop offset="0%" stopColor="#3046ff" />v
        <stop offset="25%" stopColor="#2735c8" />
        <stop offset="75%" stopColor="#2323b5" />
        <stop offset="100%" stopColor="#3046ff" />
      </linearGradient>
    </defs>
    <rect
      x="5%"
      y="5%"
      width="90%"
      height="90%"
      rx="5%"
      ry="5%"
      style={{
        fill: "url('#myGradient')",
        stroke: 'none',
      }}
    />
  </>
);

const Card = ({ className, value, ...props }: Props) => (
  <div className={styles.root}>
    <svg
      className={className}
      style={{ aspectRatio: '2.5 / 3.5' }}
      data-testid="card"
      data-card-value={value}
      viewBox="0 0 250 350"
      overflow="hidden"
      {...props}
    >
      <rect
        width="250"
        height="350"
        rx="5%"
        ry="5%"
        style={{
          fill: '#ffffff',
          stroke: 'none',
        }}
      />

      {value === undefined ? <FaceDown /> : <FaceUp value={value} />}
    </svg>
  </div>
);

export default Card;
