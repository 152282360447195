import inferHandResult from './inferHandResult';
import { ReducerState, VideoPokerAction } from './types';
import _identity from 'lodash/identity';

const awaitingDrawReducer = (state: ReducerState, action: VideoPokerAction): ReducerState => {
  switch (action.type) {
    case 'toggle-hold':
      const toToggle = action.payload;

      return {
        ...state,
        held: state.held.map((value, index) => (index === toToggle ? !value : value)),
      };
    case 'draw': {
      const held = state.held;

      if (held.every(_identity)) {
        return {
          ...state,
          phase: 'game-over',
          result: inferHandResult(state.cardValues, state),
          held: [false, false, false, false, false],
        };
      }

      const cardValues = state.cardValues.map((dealtValue, index) => {
        if (held[index]) {
          return dealtValue;
        }

        return undefined;
      });

      return {
        ...state,
        cardValues,
        held: [false, false, false, false, false],
        phase: 'animating-draw',
      };
    }
  }

  return state;
};

export default awaitingDrawReducer;
