import { createContext } from 'react';

export interface BalanceData {
  readonly balance: number;

  setBalance(balance: number): void;
  adjustBalance(balance: number): void;
  resetBalance: () => void;
}

const BalanceContext = createContext<BalanceData | undefined>(undefined);

export default BalanceContext;
