import Context, { BurgerValue } from './context';
import { useMemo, useState } from 'react';

type BurgerProviderProps = {
  children: React.ReactNode;
  initiallyOpen?: true;
};

const BurgerProvider = ({ children, initiallyOpen }: BurgerProviderProps) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen ?? false);

  const value: BurgerValue = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default BurgerProvider;
