import JacksOrBetter from '../JacksOrBetter';
import reducer from './reducer';
import { SupportedPayTable } from './types';
import { PayTableChecker } from 'casino/PayTable/Checker/types';
import { useReducer } from 'react';

type ChangePayTable = (newPayTable: SupportedPayTable) => void;

type UsePaytableRet = [PayTableChecker, ChangePayTable];

const usePayTable = (): UsePaytableRet => {
  return useReducer(reducer, JacksOrBetter);
};

export default usePayTable;
