import styles from './FillPage.module.css';
import clsx from 'clsx';
import { CSSProperties } from 'react';

type FillPageProps = {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties | undefined;
};

const FillPage = ({ children, className, style }: FillPageProps) => (
  <div className={clsx(styles.root, className)} style={style}>
    {children}
  </div>
);

export default FillPage;
