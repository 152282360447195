import JacksOrBetter from '../JacksOrBetter';
import TensOrBetter from '../TensOrBetter';
import TwoWayRoyal from '../TwoWayRoyal';
import { SupportedPayTable } from './types';
import { PayTableChecker } from 'casino/PayTable/Checker/types';

const IdToPayTable: Record<SupportedPayTable, PayTableChecker> = {
  'jacks-or-better': JacksOrBetter,
  'tens-or-better': TensOrBetter,
  'two-way-royal': TwoWayRoyal,
};

export default IdToPayTable;
