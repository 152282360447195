import { useWidth } from '.';
import ScreenSizes from 'screen/ScreenSizes';

const Phone = ({ children }: { children?: React.ReactNode }) => {
  const screenWidth = useWidth();

  if (screenWidth < ScreenSizes.getTabletMinimumWidth()) {
    return <>{children}</>;
  }

  return null;
};

export default Phone;
