import JacksOrBetterBasePayTableChecker from 'casino/PayTable/Checker/Base/JacksOrBetterBasePayTableChecker';
import { PayTableChecker } from 'casino/PayTable/Checker/types';

export class JacksOrBetterChecker extends JacksOrBetterBasePayTableChecker implements PayTableChecker {
  readonly name: string = 'Jacks or Better';

  protected static readonly RANK_JACKS_OR_BETTER = 'Jacks or Better';

  protected readonly rankAndPayouts: [string, number][] = [
    [JacksOrBetterChecker.RANK_ROYAL_FLUSH, 800],
    [JacksOrBetterChecker.RANK_STRAIGHT_FLUSH, 50],
    [JacksOrBetterChecker.RANK_FOUR_OF_A_KIND, 25],
    [JacksOrBetterChecker.RANK_FULL_HOUSE, 9],
    [JacksOrBetterChecker.RANK_FLUSH, 6],
    [JacksOrBetterChecker.RANK_STRAIGHT, 4],
    [JacksOrBetterChecker.RANK_THREE_OF_A_KIND, 3],
    [JacksOrBetterChecker.RANK_TWO_PAIRS, 2],
    [JacksOrBetterChecker.RANK_JACKS_OR_BETTER, 1],
  ];
}

export default new JacksOrBetterChecker();
