import VideoPokerGame from 'games/VideoPoker';
import VideoPokerContainer from 'games/casino/container/VideoPokerContainer';

const VideoPokerRoute = () => (
  <VideoPokerContainer title="Video Poker">
    <VideoPokerGame />
  </VideoPokerContainer>
);

export default VideoPokerRoute;
