import WindowProvider from '../../window/WindowProvider';
import WindowResizeObserverFactory from '../../window/WindowResizeObserverFactory';
import { useEffect, useState } from 'react';

const getWindowWidth = () => WindowProvider.getWindow().innerWidth;

function useWidth() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    return WindowResizeObserverFactory.createListener(() => {
      setWindowWidth(getWindowWidth);
    });
  }, []);

  return windowWidth;
}

export default useWidth;
