import { usePreloadCards } from 'casino/cards/Card';
import { useMemo } from 'react';
import { usePreloadAzeretMono } from 'text/fonts';

const usePreloadVideoPokerFamily = () => {
  const preloadCards = usePreloadCards();
  const preloadFont = usePreloadAzeretMono();

  return useMemo(async () => {
    await Promise.allSettled([preloadCards, preloadFont]);
  }, [preloadCards, preloadFont]);
};

export default usePreloadVideoPokerFamily;
