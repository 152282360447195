import BurgerContext from './context';
import { useCallback, useContext, useMemo } from 'react';

type BurgerMenuProps = {
  isOpen: boolean;
  closeMenu: () => void;
  openMenu: () => void;
};

const useBurgerMenu = (): BurgerMenuProps => {
  const contextValueMaybe = useContext(BurgerContext);

  if (!contextValueMaybe) {
    throw new Error('useBurgerMenu called outside of Context');
  }

  const { isOpen, setIsOpen } = contextValueMaybe;

  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
  const openMenu = useCallback(() => setIsOpen(true), [setIsOpen]);

  return useMemo(
    () => ({
      isOpen,
      closeMenu,
      openMenu,
    }),
    [isOpen, closeMenu, openMenu]
  );
};

export default useBurgerMenu;
