import { createContext } from 'react';

export interface Wager {
  makeDeduction: (amount: number, then: () => void) => void;
  manuallyResetBalance: () => void;
}

const WagerContext = createContext<Wager | undefined>(undefined);

export default WagerContext;
