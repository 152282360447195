import Context from './Context';
import { useState } from 'react';

// React router 6 has no Prompt component yet
const Prompt = (_: { message: string }) => null;

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [showAlert, setShowAlert] = useState(false);

  return (
    <Context.Provider value={setShowAlert}>
      <>
        {showAlert && <Prompt message="In progress game will be lost. Are you sure you want to leave now?" />}
        {children}
      </>
    </Context.Provider>
  );
};

export default Provider;
