import CrossButton from './CrossButton';
import ListItem from './ListItem';
import styles from './VideoPokerMenu.module.scss';
import Home from './icons/Home';
import { useBurgerMenu } from 'burger';
import Menu from 'burger/Menu';
import { HasPayTableName } from 'casino/PayTable/Checker/types';
import useWagerExecutor from 'casino/wager';
import lookupDisplayName from 'games/VideoPoker/PayTable/usePayTable/lookupDisplayName';
import lookupPayTableId from 'games/VideoPoker/PayTable/usePayTable/lookupPayTableId';
import { SupportedPayTable } from 'games/VideoPoker/PayTable/usePayTable/types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

const payTableIds: SupportedPayTable[] = ['jacks-or-better', 'tens-or-better', 'two-way-royal'];

type VideoPokerMenuProps = {
  changePayTable: (newTable: SupportedPayTable) => void;
  payTable: HasPayTableName;
};

const VideoPokerMenu = ({ changePayTable, payTable }: VideoPokerMenuProps) => {
  const { closeMenu } = useBurgerMenu();
  const { manuallyResetBalance } = useWagerExecutor();

  const selectedId = lookupPayTableId(payTable);

  return (
    <Menu aria-labelledby="video-poker-menu-title" className={styles.root}>
      <section className={styles.top}>
        <div className={styles.buttons}>
          <Link to="/" aria-label="Home" className={styles.home}>
            <Home />
          </Link>
          <CrossButton onClick={closeMenu} />
        </div>
        <h2 className={styles.header} id="video-poker-menu-title">
          Video Poker Menu
        </h2>
        <span>Lambton Games</span>
      </section>

      <section className={styles.section}>
        <h3>Pay tables</h3>
        <ul>
          {payTableIds.map((id) => {
            const isSelected = id === selectedId;

            return (
              <ListItem
                key={id}
                isSelected={isSelected}
                name={lookupDisplayName(id)}
                onClick={() => {
                  if (!isSelected) {
                    changePayTable(id);
                    closeMenu();
                  }
                }}
              />
            );
          })}
        </ul>
      </section>

      <section className={styles.section}>
        <h3>Options</h3>
        <ul>
          <ListItem
            hasIcon
            name="Reset Balance"
            onClick={useCallback(() => {
              manuallyResetBalance();
              closeMenu();
            }, [closeMenu, manuallyResetBalance])}
          />
        </ul>
      </section>
    </Menu>
  );
};

export default VideoPokerMenu;
