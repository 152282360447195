import { blobToDataURL } from '../DataURL';
import { fetchBlob } from 'http/fetch';
import { useQuery } from 'react-query';

type Output = {
  loading: boolean;
  lookupDataUrl: (sourcePath: string) => string;
};

const useDataUrl = (allSourcePaths: Readonly<string[]>): Output => {
  const { data, isLoading } = useQuery(
    ['VideoPoker', 'dataUrl', allSourcePaths],
    async ({ signal }) =>
      Promise.all(
        allSourcePaths.map((sourcePath) =>
          fetchBlob(sourcePath, { signal })
            .then(blobToDataURL)
            .then((dataUrl) => {
              return {
                sourcePath,
                dataUrl,
              };
            })
        )
      ),
    {
      select: (pairs) => {
        return pairs.reduce((runningValue, { dataUrl, sourcePath }) => {
          return {
            ...runningValue,
            [sourcePath]: dataUrl,
          };
        }, {} as Record<string, string>);
      },
    }
  );

  return {
    loading: isLoading,
    lookupDataUrl: (sourcePath: string) => {
      if (isLoading || !data) {
        throw new Error(`lookupDataUrl called before loading complete. requested source path = ${sourcePath}`);
      }

      const dataURL = data[sourcePath];
      if (dataURL === undefined) {
        throw new Error(`Unknown source path: ${sourcePath}`);
      }

      return dataURL;
    },
  };
};

export default useDataUrl;
