import DealDrawActionProvider from './DealDrawActionProvider';
import { PayTableChecker } from 'casino/PayTable/Checker/types';

class DealDrawActionProviderFactory {
  create(checker: PayTableChecker, stake: number) {
    return new DealDrawActionProvider(checker, stake);
  }
}

export default new DealDrawActionProviderFactory();
