type VARIABLE_NAME = 'ANALYTICS_KEY';

class EnvironmentVariableService {
  get(key: VARIABLE_NAME): string {
    if (process.env.STORYBOOK) {
      return '';
    }

    const reactVariableName = `REACT_APP_LG_${key}`;

    if (process.env[reactVariableName]) {
      return process.env[reactVariableName] as string;
    }

    throw new Error(`Environment variable "${key}" is missing`);
  }
}

export default new EnvironmentVariableService();
