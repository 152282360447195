import styles from './AndroidGames.module.scss';
import AndroidLink, { AndroidLinkProps } from './AndroidLink';
import VegasLogo from './images/Vegas.webp';
import DoubleLogo from './images/doubledown.webp';
import JokerLogo from './images/jokerwild.webp';
import SpiderLogo from './images/spider.webp';

const GameConfigs: AndroidLinkProps[] = [
  {
    iconSrc: VegasLogo,
    name: 'Vegas Solitaire',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.vegassolitairefree',
  },
  {
    iconSrc: SpiderLogo,
    name: 'Spider Solitaire',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.spidersolitairefree',
  },
  {
    iconSrc: DoubleLogo,
    name: 'Double Down Stud',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.videodoubledownstudpokerfree',
  },
  {
    iconSrc: JokerLogo,
    name: 'Joker Wild Poker',
    storeUrl: 'https://play.google.com/store/apps/details?id=com.lambtongames.jokerswild',
  },
];

const AndroidGames = () => {
  return (
    <div className={styles.root}>
      {GameConfigs.map((config) => (
        <AndroidLink key={config.name} {...config} />
      ))}
    </div>
  );
};

export default AndroidGames;
