import Footer from './Footer';
import Header from './Header';
import Justified from './Justified';
import styles from './styles.module.scss';
import clsx from 'clsx';

type SitePageProps = {
  children?: React.ReactNode;
  className?: string;
};

const SitePage = ({ children, className }: SitePageProps) => (
  <div className={clsx(styles.root, className)}>
    <Header className={styles.header} />
    <Justified>{children}</Justified>
    <Footer />
  </div>
);

export default SitePage;
