import times from 'lodash/times';
import { shuffleArrayInPlace } from 'math/random/shuffle';

class DeckFactory {
  private get52 = () => times(52, (index) => index + 1);

  private getShuffled = () => {
    const deck = this.get52();
    shuffleArrayInPlace(deck);
    return deck;
  };

  createNewShuffledSingleDeck(): number[] {
    return this.getShuffled();
  }
}

export default new DeckFactory();
