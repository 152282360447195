import AnalyticsService from 'analytics/AnalyticsService';
import { useCallback, useEffect } from 'react';
import { useCookie } from 'react-use';

const useConsents = () => {
  const [consentValue, updateConsent] = useCookie('consent');

  useEffect(() => {
    if (consentValue === 'all-accepted') {
      AnalyticsService.enable();
    } else {
      AnalyticsService.disable();
    }
  }, [consentValue]);

  const acceptAll = useCallback(() => {
    updateConsent('all-accepted', { expires: 365 });
  }, [updateConsent]);

  const declineAll = useCallback(() => {
    updateConsent('none', { expires: 365 });
  }, [updateConsent]);

  return {
    consentValue,
    acceptAll,
    declineAll,
    performanceAccepted: consentValue === 'all-accepted',
  };
};

export default useConsents;
