import PayRow from './PayRow';
import styles from './Paytable.module.scss';
import { PaytableRows } from './types';
import clsx from 'clsx';

interface PayTableProps {
  className?: string;
  totalCoins: number;
  payTable: PaytableRows;
  winningRank?: string;
}

const PayTable = ({ className, totalCoins: coinSize, payTable, winningRank }: PayTableProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      {payTable.rows.map((row) => (
        <PayRow data={row} key={row.rank} totalCoins={coinSize} win={winningRank === row.rank} />
      ))}
    </div>
  );
};

export default PayTable;
