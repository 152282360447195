class PromiseFactory {
  create<T>() {
    let resolve: (value: T | PromiseLike<T>) => void;
    let reject: (reason?: any) => void;

    const promise = new Promise<T>((promiseResolve, promiseReject) => {
      resolve = promiseResolve;
      reject = promiseReject;
    });

    return {
      // @ts-expect-error we know this has to be assigned
      reject,
      // @ts-expect-error we know this has to be assigned
      resolve,
      promise,
    };
  }
}

export default new PromiseFactory();
