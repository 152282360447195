import styles from './styles.module.scss';
import clsx from 'clsx';

type SectionProps = {
  children?: React.ReactNode;
  className?: string;
  title: string;
};

const Section = ({ children, className, title }: SectionProps) => {
  return (
    <section className={clsx(styles.root, className)}>
      <h2 className={styles.title}> {title} </h2>
      {children}
    </section>
  );
};

export default Section;
