
import PeristenceProvider from 'persistence/Provider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import WithTheme from 'theme/WithTheme';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

// Should be last, known issue with header/footer link color conflict
import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <PeristenceProvider namespace="lambton-games">
      <WithTheme>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </WithTheme>
    </PeristenceProvider>
  </React.StrictMode>
);
