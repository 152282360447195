import styles from './AndroidGames.module.scss';

export type AndroidLinkProps = {
  iconSrc: string;
  name: string;
  storeUrl: string;
};

const AndroidLink = ({ iconSrc, name, storeUrl }: AndroidLinkProps) => {
  return (
    <a href={storeUrl} className={styles.card}>
      <img src={iconSrc} alt="" width="148" height="148" />
      <div>{name}</div>
    </a>
  );
};

export default AndroidLink;
