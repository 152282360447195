import { SupportedPayTable } from '../PayTable/usePayTable/types';
import { GamePresentation } from '../state/types';
import ButtonBar from './ButtonBar';
import CardCell from './CardCell';
import Hud from './Hud';
import TitleBar from './TitleBar';
import VideoPokerMenu from './VideoPokerMenu';
import styles from './styles.module.scss';
import PayTable from 'casino/PayTable';
import { PayTableChecker } from 'casino/PayTable/Checker/types';
import { useMemo } from 'react';

type GameAreaProps = {
  balance: number;
  checker: PayTableChecker;
  presentation: GamePresentation;

  onDealDraw: () => void;
  onCycleCoinSize: () => void;
  onCycleTotalCoins: () => void;
  toggleHold: (index: number) => void;
  onChangePayTable: (newTable: SupportedPayTable) => void;
};

const GameArea = ({
  balance,
  checker,
  presentation,
  onDealDraw,
  onChangePayTable,
  onCycleCoinSize,
  onCycleTotalCoins,
  toggleHold,
}: GameAreaProps) => {
  const { bet, result, cardValues, held, coinSize, totalCoins, phase } = presentation;

  const toggleHolds = useMemo<Array<() => void>>(
    () => [0, 1, 2, 3, 4].map((index) => () => toggleHold(index)),
    [toggleHold]
  );

  const performFadingOfLosingCards = phase === 'game-over';

  return (
    <div className={styles.root}>
      <VideoPokerMenu payTable={checker} changePayTable={onChangePayTable} />

      <TitleBar className={styles.title} title={checker.name} />
      <div className={styles.innards}>
        <PayTable className={styles.payTable} payTable={checker} totalCoins={totalCoins} winningRank={result?.rank} />

        <div className={styles.cards}>
          {cardValues.map((value, index) => {
            const fade = performFadingOfLosingCards && !result?.winningCards[index];

            return (
              <CardCell
                key={index}
                acceptingInput={phase === 'awaiting-draw'}
                fade={fade}
                held={held[index]}
                onClick={toggleHolds[index]}
                value={value}
              />
            );
          })}
        </div>
      </div>
      <Hud bet={bet} win={result ? result.win : 0} balance={balance} />
      <ButtonBar
        className={styles.buttonBar}
        coinSize={coinSize}
        phase={phase}
        cycleCoinSize={onCycleCoinSize}
        totalCoins={totalCoins}
        cycleTotalCoins={onCycleTotalCoins}
        dealDraw={onDealDraw}
      />
    </div>
  );
};

export default GameArea;
