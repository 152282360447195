import styles from './styles.module.scss';
import { BurgerButton } from 'burger/button';
import clsx from 'clsx';

type TitleBarProps = {
  className?: string;
  title: string;
};

const TitleBar = ({ className, title }: TitleBarProps) => (
  <div className={clsx(styles.root, className)} data-testid="title-bar">
    <BurgerButton className={styles.menuButton} />
    {title.toLocaleUpperCase()}
  </div>
);

export default TitleBar;
