import styles from './styles.module.scss';
import clsx from 'clsx';

type Props = {
  onAcceptAll: () => void;
  onDecline: () => void;
  className?: string;
};

const ConsentModal = (props: Props) => {
  return (
    <div className={clsx(styles.root, props.className)}>
      <div>
        We use cookies to give you the best online experience. <br />
        Please let us know if you accept all cookies.
      </div>
      <div className={styles.buttons}>
        <button onClick={() => props.onDecline()}>Decline</button>
        <button onClick={() => props.onAcceptAll()}>Accept All</button>
      </div>
    </div>
  );
};

export default ConsentModal;
