import WagerContext from './WagerContext';
import { useContext } from 'react';

const useWagerExecutor = () => {
  const dataMaybe = useContext(WagerContext);

  if (!dataMaybe) {
    throw new Error('useWagerExecutor called outside of WagerContext');
  }

  return dataMaybe;
};

export default useWagerExecutor;
