import styles from './index.module.scss';
import { useEffect } from 'react';

const useBodyStyles = (className: string) => {
  useEffect(() => {
    document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, [className]);
};

type WithThemeProps = {
  children?: React.ReactNode;
};

const WithTheme = ({ children }: WithThemeProps) => {
  useBodyStyles(styles.root);

  return <>{children}</>;
};

export default WithTheme;
