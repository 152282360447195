import allCardsAreRevealed from './allCardsAreRevealed';
import inferHandResult from './inferHandResult';
import { ReducerState, VideoPokerAction } from './types';
import _isUndefined from 'lodash/isUndefined';

const animatingDrawReducer = (state: ReducerState, action: VideoPokerAction): ReducerState => {
  switch (action.type) {
    case 'draw': {
      const stock = [...state.stock];
      const cardValues: number[] = state.cardValues.map((existingValue) => {
        return existingValue === undefined ? (stock.shift() as number) : existingValue;
      });

      return {
        ...state,
        cardValues,
        phase: 'game-over',
        result: inferHandResult(cardValues, state),
        stock,
      };
    }
    case 'animation tick': {
      const stock = [...state.stock];
      const cardValues = [...state.cardValues];
      const firstUnnrevealedIndex = cardValues.findIndex(_isUndefined);

      if (firstUnnrevealedIndex >= 0) {
        cardValues[firstUnnrevealedIndex] = stock.shift();
      }

      const allRevealed = allCardsAreRevealed(cardValues);

      return {
        ...state,
        cardValues,
        stock,
        phase: allRevealed ? 'game-over' : 'animating-draw',
        result: inferHandResult(cardValues, state),
      };
    }
  }

  return state;
};

export default animatingDrawReducer;
