import FooterButtonBar from './FooterButtonBar';
import LinkBar from './LinkBar';
import styles from './styles.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <FooterButtonBar />
      <LinkBar />
    </footer>
  );
}

export default Footer;
