import styles from './BurgerButton.module.scss';
import { useBurgerMenu } from 'burger';
import clsx from 'clsx';
import { useCallback } from 'react';

const Layer = ({ y }: { y: number | string }) => (
  <rect
    style={{
      fill: 'currentcolor',
    }}
    width="20"
    x="2"
    y={y}
    height="4"
    rx="1"
  />
);

const BurgerButton = ({ className }: { className?: string }) => {
  const { openMenu } = useBurgerMenu();

  return (
    <div
      className={clsx(styles.root, className)}
      role="button"
      onClick={useCallback(() => openMenu(), [openMenu])}
      aria-label="Open menu"
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g>
          <Layer y="4" />
          <Layer y="10" />
          <Layer y="16" />
        </g>
      </svg>
    </div>
  );
};

export default BurgerButton;
