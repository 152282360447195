import { VideoPokerAction } from '../reducer/types';
import DealDrawActionProviderFactory from './DealDrawActionProviderFactory';
import { PayTableChecker } from 'casino/PayTable/Checker/types';
import { useMemo } from 'react';

const useDealDrawActions = (
  checker: PayTableChecker,
  stake: number
): {
  getDealAction: () => VideoPokerAction;
  getDrawAction: () => VideoPokerAction;
} => useMemo(() => createProvider(checker, stake), [checker, stake]);

function createProvider(checker: PayTableChecker, stake: number) {
  const provider = DealDrawActionProviderFactory.create(checker, stake);

  return {
    getDealAction: () => provider.getDealAction(),
    getDrawAction: () => provider.getDrawAction(),
  };
}

export default useDealDrawActions;
