import Justified from '../Justified';
import styles from './styles.module.scss';
import clsx from 'clsx';
import GoToRoute from 'routes/links/GoToRoute';

type HeaderProps = {
  className?: string;
};

const Header = ({ className }: HeaderProps) => (
  <header className={clsx(styles.header, className)}>
    <Justified>
      <h1>
        <GoToRoute name="Lambton Games" destination="/" />
      </h1>
    </Justified>
  </header>
);

export default Header;
