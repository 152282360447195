import BalanceContext from './BalanceContext';
import usePersistence from 'persistence/usePersistence';
import React, { useCallback, useMemo, useState } from 'react';

export const BALANCE_KEY = 'balance';
const RESET_BALANCE_VALUE = 5000;

export const getResetBalanceValue = () => RESET_BALANCE_VALUE;

const getInitialBalance = (getItem: (key: string) => string | null) => {
  const savedBalance = getItem(BALANCE_KEY);

  if (savedBalance === null) {
    return RESET_BALANCE_VALUE;
  }

  return Number(savedBalance);
};

const PersistBalance = ({ children }: { children?: React.ReactNode }) => {
  const { getItem, setItem } = usePersistence();
  const [balance, setBalance] = useState(() => getInitialBalance(getItem));

  const adjustBalance = useCallback(
    (adjustBy: number) => {
      setBalance((existingBalance) => {
        const newBalance = existingBalance + adjustBy;
        setItem(BALANCE_KEY, `${newBalance}`);
        return newBalance;
      });
    },
    [setItem]
  );

  const resetBalance = useCallback(() => {
    setItem(BALANCE_KEY, `${RESET_BALANCE_VALUE}`);
    setBalance(5000);
  }, [setItem]);

  const updateBalance = useCallback(
    (newBalance: number) => {
      setItem(BALANCE_KEY, newBalance.toString());
      setBalance(newBalance);
    },
    [setItem]
  );

  return (
    <BalanceContext.Provider
      value={useMemo(
        () => ({
          adjustBalance,
          balance,
          resetBalance,
          setBalance: updateBalance,
        }),
        [balance, adjustBalance, resetBalance, updateBalance]
      )}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export default PersistBalance;
