import IdToPayTable from './lookupTable';
import { SupportedPayTable } from './types';
import { PayTableChecker } from 'casino/PayTable/Checker/types';

const reducer = (_: PayTableChecker, action: SupportedPayTable) => {
  const out = IdToPayTable[action];
  if (!out) {
    throw new Error(`Unknown pay table "${action}"`);
  }
  return out;
};

export default reducer;
