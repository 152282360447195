import preloadFont from 'caching/preloadFonts';
import { useMemo } from 'react';

const usePreloadAzeretMono = () => {
  return useMemo(
    () => preloadFont('Azeret Mono', `${process.env.PUBLIC_URL}/fonts/azeret-mono-v8-latin-regular.woff2`),
    []
  );
};

export default usePreloadAzeretMono;
