import styles from './ButtonBar.module.scss';
import clsx from 'clsx';
import { GamePhase } from 'games/VideoPoker/state/types';

type ButtonBarProps = {
  className?: string;
  coinSize: number;
  cycleCoinSize: () => void;
  cycleTotalCoins: () => void;
  dealDraw: () => void;
  phase: GamePhase;
  totalCoins: number;
};

const ButtonBar = ({
  className,
  coinSize,
  cycleCoinSize,
  cycleTotalCoins,
  dealDraw,
  phase,
  totalCoins,
}: ButtonBarProps) => {
  const coinSizeButtonsDisabled = phase !== 'game-over' && phase !== 'initial';
  const dealButtonShowsDrawCaption = phase === 'awaiting-draw' || phase === 'animating-draw';

  return (
    <div className={clsx(styles.root, className)}>
      <button
        data-testid="coin-size-button"
        disabled={coinSizeButtonsDisabled}
        onClick={cycleCoinSize}
      >{`COIN SIZE: ${coinSize}`}</button>
      <button
        data-testid="total-coins-button"
        disabled={coinSizeButtonsDisabled}
        onClick={cycleTotalCoins}
      >{`COINS: ${totalCoins}`}</button>
      <button data-testid="deal-draw-button" onClick={dealDraw}>
        {dealButtonShowsDrawCaption ? 'DRAW' : 'DEAL'}
      </button>
    </div>
  );
};

export default ButtonBar;
