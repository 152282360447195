import IdToPayTable from './lookupTable';
import { SupportedPayTable } from './types';
import { HasPayTableName } from 'casino/PayTable/Checker/types';

const lookupPayTableId = ({ name }: HasPayTableName): SupportedPayTable | null => {
  for (const [key, value] of Object.entries(IdToPayTable)) {
    if (value.name === name) {
      return key as SupportedPayTable;
    }
  }

  return null;
};

export default lookupPayTableId;
