import { JacksOrBetterChecker } from '../JacksOrBetter';
import { PayTableChecker, Result } from 'casino/PayTable/Checker/types';

class TwoWayRoyalChecker extends JacksOrBetterChecker implements PayTableChecker {
  private static readonly RANK_HI_ROYAL_FLUSH = 'HI Royal Flush';
  private static readonly RANK_LO_ROYAL_FLUSH = 'LO Royal Flush';

  readonly name: string = 'Two Way Royal';

  protected rankAndPayouts: [string, number][] = [
    [TwoWayRoyalChecker.RANK_HI_ROYAL_FLUSH, 800],
    [TwoWayRoyalChecker.RANK_LO_ROYAL_FLUSH, 800],
    [TwoWayRoyalChecker.RANK_STRAIGHT_FLUSH, 50],
    [TwoWayRoyalChecker.RANK_FOUR_OF_A_KIND, 40],
    [TwoWayRoyalChecker.RANK_FULL_HOUSE, 6],
    [TwoWayRoyalChecker.RANK_FLUSH, 5],
    [TwoWayRoyalChecker.RANK_STRAIGHT, 4],
    [TwoWayRoyalChecker.RANK_THREE_OF_A_KIND, 3],
    [TwoWayRoyalChecker.RANK_TWO_PAIRS, 2],
    [TwoWayRoyalChecker.RANK_JACKS_OR_BETTER, 1],
  ];

  public getHandResult(hand: number[]): Result {
    const output = super.getHandResult(hand);

    if (output.rank === JacksOrBetterChecker.RANK_ROYAL_FLUSH) {
      return {
        ...output,
        rank: TwoWayRoyalChecker.RANK_HI_ROYAL_FLUSH,
      };
    }

    if (output.rank === TwoWayRoyalChecker.RANK_STRAIGHT_FLUSH) {
      const faceValues = TwoWayRoyalChecker.toFaceValues(hand);
      if (faceValues.includes(2) && faceValues.includes(6)) {
        return {
          ...output,
          rank: TwoWayRoyalChecker.RANK_LO_ROYAL_FLUSH,
        };
      }
    }

    return output;
  }
}

export default new TwoWayRoyalChecker();
