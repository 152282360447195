import Context from '../Context';
import lookupSuit from './lookupSuit';
import lookupValue from './lookupValue';
import { useContext } from 'react';

export type CardSources = {
  suitSrc: string;
  valueSrc: string;
};

const useCardImages = (value: number): CardSources => {
  const contextMaybe = useContext(Context);

  const suitSrc = lookupSuit(value);
  const valueSrc = lookupValue(value);

  if (contextMaybe) {
    return {
      suitSrc: contextMaybe.getDataUrlSvg(suitSrc),
      valueSrc: contextMaybe.getDataUrlSvg(valueSrc),
    };
  }

  return {
    suitSrc,
    valueSrc,
  };
};

export default useCardImages;
