import SitePage from '../../../pages/SitePage';
import useDocumentTitle from '@tanem/use-document-title';
import { Link } from 'react-router-dom';

const Privacy = () => {
  useDocumentTitle('Lambton Games – Privacy');

  return (
    <SitePage>
      <section>
        <h2>Cookie Policy</h2>
        <p>
          Cookies are small text files which are transferred to your computer or mobile when you visit a website or app.
        </p>
        <p>We use them to:</p>
        <ul>
          <li>Remember information about you, so you don’t have to give it to us again</li>
          <li>Help us understand how people are using our services, so we can make them better</li>
          <li>To deliver advertising tailored to you</li>
          <li>Help us personalise content for you, including remembering your preference, settings and game state</li>
        </ul>
        <p>
          You can change your cookie preferences <Link to={'/cookies'}>here</Link>
        </p>
      </section>

      <section>
        <h2>Privacy</h2>
        <h3>Google Analytics</h3>
        <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. (‘Google’).</p>
        <p>
          Google Analytics uses cookies to help Lambton Games analyse how users use this website. The information
          generated by the cookie about your use of this website (including your IP address) will be transmitted to and
          stored by Google on servers in the United States. Google will use this information for the purpose of
          evaluating your use of this website, compiling reports on website activity for website operators and providing
          other services relating to website activity and internet usage. Google may also transfer this information to
          third parties where required to do so by law, or where such third parties process the information on Google’s
          behalf. Google will not associate your IP address with any other data held by Google.
        </p>
        <p>
          You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note
          that if you do this you may not be able to use the full functionality of this website.
        </p>
        <p>
          By using this website, you consent to the processing of data about you by Google in the manner and for the
          purposes set out above.
        </p>
      </section>
    </SitePage>
  );
};

export default Privacy;
