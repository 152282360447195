import styles from './styles.module.scss';
import clsx from 'clsx';
import _isFunction from 'lodash/isFunction';
import Modal from 'react-modal';

type SharedProps = {
  message: string;
  title: string;
};

export type YesNoProps = SharedProps & {
  yesCaption?: string;
  noCaption?: string;
  onYes: () => void;
  onNo: () => void;
};

export type OkProps = SharedProps & {
  onOk: () => void;
};

type AlertDialogProps = OkProps | YesNoProps;

function isOkProps(maybe: AlertDialogProps): maybe is OkProps {
  return _isFunction((maybe as OkProps).onOk);
}

const AlertDialog = (props: AlertDialogProps) => {
  const { message, title } = props;
  const isOk = isOkProps(props);

  return (
    <Modal
      isOpen
      appElement={document.getElementById('root') || undefined}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <h1 data-testid="alert-title">{title}</h1>
      <p className={styles.message} data-testid="alert-message">
        {message}
      </p>
      <div className={clsx(styles.buttons, isOk && styles.solo)} role="group">
        {isOk ? (
          <button data-testid="alert-ok" onClick={() => props.onOk()}>
            OK
          </button>
        ) : (
          <>
            <button data-testid="alert-no" onClick={() => props.onNo()}>
              {props.noCaption ?? 'No'}
            </button>
            <button data-testid="alert-yes" onClick={() => props.onYes()}>
              {props.yesCaption ?? 'Yes'}
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AlertDialog;
