import styles from './Paytable.module.scss';
import { PayRow as PayRowData } from './types';
import clsx from 'clsx';

interface PayRowProps {
  data: PayRowData;
  totalCoins: number;
  win: boolean;
}

const MULTIPLIERS = [1, 2, 3, 4, 5];

const PayRow = ({ totalCoins, data, win }: PayRowProps) => {
  const { rank, payout } = data;

  return (
    <>
      <div className={styles.rank}>
        <span
          data-testid="rank-name"
          className={clsx({
            [styles.win]: win,
          })}
        >
          {rank}
        </span>
      </div>

      {MULTIPLIERS.map((multiplier) => {
        const selected = multiplier === totalCoins;

        return (
          <div
            data-testid="payout-cell"
            className={clsx(styles.payout, {
              [styles.selected]: selected,
            })}
            key={multiplier}
          >
            <span
              data-testid="payout"
              className={clsx({
                [styles.win]: selected && win,
              })}
            >
              {multiplier * payout}
            </span>
          </div>
        );
      })}
    </>
  );
};

export default PayRow;
