import styles from './styles.module.scss';
import useConsents from './useConsents';
import ConsentModal from 'consents/ConsentModal';

const ConsentOverlay = () => {
  const { consentValue, acceptAll, declineAll } = useConsents();

  if (consentValue) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ConsentModal onAcceptAll={acceptAll} onDecline={declineAll} />
    </div>
  );
};

export default ConsentOverlay;
