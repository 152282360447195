import reducer from './reducer';
import { ReducerState } from './reducer/types';
import throwError from '@ducktorscience/throw-error';
import { PayTableChecker } from 'casino/PayTable/Checker/types';
import { useEffect, useReducer } from 'react';

const INITIAL_STATE = Object.freeze<ReducerState>({
  cardValues: [undefined, undefined, undefined, undefined, undefined],
  checker: {
    getHandResult: throwError('initial state checker not expected to ever be called'),
    rows: [],
  },
  held: [false, false, false, false, false],
  phase: 'initial',
  stock: [],
  result: null,
  stake: 0,
});

const useGameStateReducer = (checker: PayTableChecker) => {
  const init = (initial: ReducerState): ReducerState => {
    return {
      ...initial,
    };
  };

  const [gameState, dispatch] = useReducer(reducer, INITIAL_STATE, init);

  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [checker]);

  return {
    ...gameState,
    dispatch,
  };
};

export default useGameStateReducer;
