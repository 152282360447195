import styles from './Menu.module.scss';
import OpenMenu from './OpenMenu';
import { useBurgerMenu } from 'burger';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

const Menu = (props: React.ComponentProps<typeof OpenMenu>) => {
  const { isOpen } = useBurgerMenu();

  return (
    <CSSTransition
      in={isOpen}
      unmountOnExit={true}
      mountOnEnter={true}
      appear={isOpen}
      timeout={90}
      classNames={{
        enter: styles.animEnter,
        enterActive: styles.animEnterActive,
        exit: styles.animExit,
        exitActive: styles.animExitActive,
      }}
    >
      <OpenMenu {...props} />
    </CSSTransition>
  );
};

export default Menu;
