import BalanceContext from './BalanceContext';
import { useContext } from 'react';

const useBalanceData = () => {
  const balanceDataMaybe = useContext(BalanceContext);

  if (!balanceDataMaybe) {
    throw new Error('useBalanceData called outside of BalanceContext');
  }

  return balanceDataMaybe;
};

export default useBalanceData;
