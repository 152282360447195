import { Result } from '../types';
import NonWildBasePayTableChecker from './NonWildBasePayTableChecker';

abstract class JacksOrBetterBasePayTableChecker extends NonWildBasePayTableChecker {
  protected static readonly RANK_JACKS_OR_BETTER = 'Jacks or Better';

  public getHandResult(hand: number[]): Result {
    const isFlush = JacksOrBetterBasePayTableChecker.isFlush(hand);
    const isStraight = JacksOrBetterBasePayTableChecker.isStraight(hand);

    if (isFlush && isStraight) {
      if (
        JacksOrBetterBasePayTableChecker.containsFaceValue(hand, 1) &&
        JacksOrBetterBasePayTableChecker.containsFaceValue(hand, 10)
      ) {
        return JacksOrBetterBasePayTableChecker.createAllWin(JacksOrBetterBasePayTableChecker.RANK_ROYAL_FLUSH);
      }

      return JacksOrBetterBasePayTableChecker.createAllWin(JacksOrBetterBasePayTableChecker.RANK_STRAIGHT_FLUSH);
    }

    if (JacksOrBetterBasePayTableChecker.isNofKind(hand, 4)) {
      return JacksOrBetterBasePayTableChecker.createPairWin(JacksOrBetterBasePayTableChecker.RANK_FOUR_OF_A_KIND, hand);
    }

    if (JacksOrBetterBasePayTableChecker.isFullHouse(hand)) {
      return JacksOrBetterBasePayTableChecker.createAllWin(JacksOrBetterBasePayTableChecker.RANK_FULL_HOUSE);
    }

    if (isFlush) {
      return JacksOrBetterBasePayTableChecker.createAllWin(JacksOrBetterBasePayTableChecker.RANK_FLUSH);
    }

    if (isStraight) {
      return JacksOrBetterBasePayTableChecker.createAllWin(JacksOrBetterBasePayTableChecker.RANK_STRAIGHT);
    }

    if (JacksOrBetterBasePayTableChecker.isNofKind(hand, 3)) {
      return JacksOrBetterBasePayTableChecker.createPairWin(
        JacksOrBetterBasePayTableChecker.RANK_THREE_OF_A_KIND,
        hand
      );
    }

    if (JacksOrBetterBasePayTableChecker.isTwoPair(hand)) {
      return JacksOrBetterBasePayTableChecker.createPairWin(JacksOrBetterBasePayTableChecker.RANK_TWO_PAIRS, hand);
    }

    if (JacksOrBetterBasePayTableChecker.isValueOrBetter(hand, 11)) {
      return JacksOrBetterBasePayTableChecker.createPairWin(
        JacksOrBetterBasePayTableChecker.RANK_JACKS_OR_BETTER,
        hand
      );
    }

    return JacksOrBetterBasePayTableChecker.NO_WIN;
  }
}

export default JacksOrBetterBasePayTableChecker;
