import styles from './styles.module.scss';

interface Props {
  destination: string;
  name: string;
  src: string;
}

function FooterButton(props: Props) {
  return (
    <a href={props.destination} target="_blank" rel="noreferrer">
      <img src={props.src} alt={props.name} className={styles.footerButton} />
    </a>
  );
}

export default FooterButton;
